@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.timestamp {
  white-space: nowrap;
}

.table {
  width: 100%;
}

.list {
  @include listWrapper();

  .header {
    display: flex;
    min-height: $largeFieldHeight;
    justify-content: right;
    align-items: center;
    padding: $offset3 $offset4;
    border-bottom: 1px solid $blue20;

    .button {
      width: 124px;
    }
  }
}
