@import 'globalStyles/variables';

.container {
  display: flex;
  gap: $offset6;
}

.content {
  width: 100%;
}
