@import 'globalStyles/variables';

.notification {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  padding: $offset4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.supplier {
  padding: $offset1 $offset2;
  background-color: $blue50;
  border-radius: $borderRadius2;
}

.status {
  margin: $offset3 0;
}

.message {
  margin-bottom: $offset4;
}
