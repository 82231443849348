@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset6;
  padding: $offset2;
}

.form-items-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;
}

.button-wrapper {
  width: 200px;

  &:last-child {
    margin-left: $offset4;
  }
}

.complete-button {
  width: 200px;
  margin-left: auto;
}
