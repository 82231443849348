@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.header-pick-up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title-block {
  width: 100%;
  display: flex;
}

.header-first-title {
  margin-right: $offset12;
  width: 240px;
}
.header-second-title {
  margin-left: $offset12;
  width: 240px;
}

.header-price {
  display: flex;
  align-items: center;
  gap: $offset2;
}

.location-info {
  display: flex;
  align-items: center;
}

.from-location-item {
  min-width: 336px;
  margin-right: $offset4;
}

.list {
  @include listWrapper();
  overflow: hidden;

  .table {
    width: 100%;

    .company-id {
      display: flex;
      align-items: center;
      gap: $offset2;
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
