@mixin Body1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.005em;
}

@mixin body2WMedium {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.007em;
}

@mixin body2WBold {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.007em;
}

@mixin H1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

@mixin H2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

@mixin H3 {
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.005em;
}

@mixin H4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

@mixin H5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

@mixin H6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

@mixin Button1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.005em;
}

@mixin Button2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.005em;
}

@mixin Subtitle1 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: -0.005em;
}

@mixin Subtitle2 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}

@mixin Subtitle3 {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  font-weight: bold;
}

@mixin Subtitle4 {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
}

@mixin Subtitle5 {
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  font-weight: bold;
}

@mixin Subtitle6 {
  font-size: 12px;
  line-height: 18px;
  font-style: italic;
  font-weight: bold;
}

@mixin Subtitle7 {
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
}

@mixin Button2Underline {
  text-decoration: underline;
}

@mixin subtitleWBold {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.1px;
}
