@import './variables';

@mixin breadcrumbs() {
  height: $defaultBreadcrumbsHeight;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;

  ol {
    padding: 0;
    margin: 0;
  }
}

@mixin listWrapper() {
  background-color: $whiteMaster;
  border-radius: $borderRadius6;
  border: 1px solid $blue20;
  box-shadow: $shadowSoft;
}

@mixin thinScrollbar() {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: $whiteMaster;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey300;
    border-radius: $borderRadius10;
  }
}
