@import 'globalStyles/variables';

.item {
  background-color: $whiteMaster;
  padding: $offset2;
  width: fit-content;
  border: 1px solid $blue700;
  border-radius: $borderRadius4;
  cursor: pointer;
  display: flex;
  gap: $offset1;

  &.active {
    border-color: $blue20;
  }

  &.disabled {
    cursor: not-allowed;
    border-color: $blue20;
  }
}
