@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  gap: $offset4;
  margin-top: $offset6;

  .form-item {
    margin-bottom: $offset4;
    width: 100%;
  }
}

.radio-button-label {
  margin-right: $offset6;
}

.radio-button {
  margin-right: $offset4;
}
