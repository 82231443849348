@import 'globalStyles/variables';

.container {
  width: 100%;
  height: 100%;
  padding: $offset6;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  margin-bottom: $offset6;
}
