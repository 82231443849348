@import 'globalStyles/variables';

.badge {
  display: inline-block;
  padding: 0 $offset2;
  width: fit-content;
  border-radius: $borderRadius2;

  &.error {
    background-color: $red50;
  }

  &.warning {
    background-color: $yellow50;
  }

  &.ok {
    background-color: $green50;
  }

  &.pending {
    background-color: $blue10;
  }
}
