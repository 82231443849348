@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  gap: $offset4;

  .form-item {
    margin-bottom: $offset4;
    width: 32%;
  }
}

.form-items-row:last-child {
  margin-top: $offset2;
  border-top: 1px solid $blue20;
  padding-top: $offset6;
}

.form-item-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.badge {
  margin-bottom: $offset6;
}
