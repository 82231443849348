@import 'globalStyles/variables';

.heading-wrapper {
  display: flex;
  align-items: center;
}

.button-wrapper {
  width: 124px;
  margin-bottom: $offset4;
}
