@import 'globalStyles/mixins';

.rates-modal {
  width: 1248px;
  height: 100%;
  padding: $offset6;
}

.list {
  @include listWrapper();

  .trash-icon {
    width: 100%;
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $offset3;
    border-bottom: 1px solid $blue20;

    .buttons {
      display: flex;

      .filter-icon {
        border-left: 1px solid $blue20;
        padding-left: $offset4;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .button {
        margin-right: $offset3;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue10;
        border-radius: $borderRadius4;
        height: 32px;
        width: 124px;
      }
    }
  }

  .table {
    width: 100%;
  }
}

.pagination {
  margin-top: $offset6;
}

.season-range-wrapper {
  border-bottom: 1px solid $blue20;
  margin-bottom: $offset6;

  .season-range {
    width: 392px;
  }
}

.price-list-id {
  width: fit-content;
  padding: $offset2 $offset4;
  background-color: $blue10;
  border-radius: $borderRadius4;
  margin-bottom: $offset6;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: $offset4;

  .action-button {
    width: 200px;
  }
}

.timestamp {
  white-space: nowrap;
}
