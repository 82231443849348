@import 'globalStyles/variables';

.working-item-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.working-item-info-icon {
  margin-top: calc($offset1 / 2);
  margin-right: $offset3;
}

.working-item-info-label {
  width: 100%;
  display: block;
}

.working-item-info-value {
  margin-left: $offset1;
}
