.tooltip-wrapper {
  display: flex;
}

.tooltip-content {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
