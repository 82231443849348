@import 'globalStyles/variables';

.form-items-row {
  display: flex;

  .form-item {
    width: 100%;
  }

  .form-item:nth-child(2) {
    margin-left: $offset4;
  }
}

.alert {
  margin-bottom: $offset4;
}
