@import 'globalStyles/variables';

.dropdown-header {
  border-radius: $borderRadius4;
  border: 1px solid $blue20;
  cursor: pointer;
  padding-left: $offset3;
  position: relative;
  align-items: center;
  display: flex;
  background-color: $whiteMaster;

  &-no-border {
    border: none;
  }

  &.small {
    height: $smallFieldHeight;
  }

  &.medium {
    height: $mediumFieldHeight;
  }

  &.large {
    height: $largeFieldHeight;
  }

  &-item-icon {
    right: $offset3;
    position: absolute;
    transition: transform 300ms, top 300ms;
    transform: rotate(180deg);
  }

  &.dropdown-header-active {
    .dropdown-header-item-icon {
      top: 12px;
      transform: rotate(0);
    }
  }

  &:hover,
  &-active {
    background: $blue10;
    border-color: $blue700;
  }
}
