@import 'globalStyles/variables';

.signin-form {
  max-width: 720px;
  margin: $offset26 auto 0;

  .header {
    margin-bottom: $offset2;
  }

  .form-fields {
    width: 100%;
    margin-top: $offset8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .badge {
      margin-bottom: $offset4;
    }

    .buttons {
      display: flex;
      gap: $offset8;
      align-items: center;

      .button-wrapper {
        width: 288px;

        &:last-child {
          margin: 0 auto;
        }
      }
    }
  }
}
