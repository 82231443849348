@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.content {
  width: 624px;
  padding-left: $offset6;
  padding-bottom: $offset2;

  .list {
    @include thinScrollbar();
    padding-right: $offset2;
    overflow-y: auto;
    max-height: 240px;
  }
}
