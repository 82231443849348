@import 'globalStyles/variables';

.form {
  display: flex;
  gap: $offset6;

  .input-wrapper {
    width: 100%;
  }

  .form-fields-row {
    display: flex;

    .input-wrapper:nth-child(2) {
      margin-left: $offset6;
    }
  }
}

.captcha {
  margin: 0 auto $offset6;
}
