@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.table {
  width: 100%;

  .table-content {
    table-layout: fixed;
  }
}

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $offset3 $offset4;
    border-bottom: 1px solid $blue20;

    .right {
      display: flex;

      .date-picker {
        width: 208px;
        margin: 0 $offset4;
      }

      .button {
        display: flex;
        margin-right: $offset3;
      }
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
