@import 'globalStyles/variables';

$borderTransparent: 1px solid transparent;

.header {
  width: 100%;
  background-color: $blue900Master;
  height: $defaultHeaderHeight;
  position: fixed;
  z-index: 100;

  .content {
    width: 1200px;
    height: $defaultHeaderHeight;
    margin: 0 auto;
    display: flex;
    padding-left: $offset10;
    justify-content: space-between;
    align-items: center;

    .info-block {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;

      &:has(.notification:hover) {
        .tutorial > .tips-button {
          border-right: $borderTransparent;
        }

        .user-data {
          border-left: $borderTransparent;
        }
      }

      .tutorial {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .notification {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 $offset5;

        &:hover {
          background-color: $blue700;
          cursor: pointer;
        }
      }

      .user-data-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        .user-data {
          display: flex;
          border-left: 1px solid $blueSecondary;
          border-right: 1px solid $blueSecondary;
          align-items: center;
          height: 39px;
          padding: 0 $offset4;

          &.without-border {
            border-left: none;
          }

          .user-avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $blue20;
          }

          .user-avatar-label {
            display: flex;
            user-select: none;
            height: 100%;
            justify-content: center;
            align-items: center;
            color: $grey500;
          }

          &:hover,
          &.active {
            height: 100%;
            background-color: $blue700;
            cursor: pointer;
            border-left: $borderTransparent;
            border-right: $borderTransparent;
          }

          .info {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin-right: $offset2;
          }
        }

        .logout {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: $offset3;
          cursor: pointer;
          top: 84px;
          right: 0px;
          width: 253px;
          border: 1px solid #e7e7f2;
          height: $largeFieldHeight;
          color: $blue700;
          border-radius: $borderRadius4;
          background-color: $whiteMaster;

          &:hover {
            .label {
              color: $blue700;
            }

            & > svg {
              path {
                stroke: $blue700;
              }
            }
          }

          &:hover,
          &:hover::after {
            background-color: $blue10;
          }

          &::before,
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            background-color: $whiteMaster;
            position: absolute;
            border-left: 1px solid $blue20;
            border-bottom: 1px solid $blue20;
            top: -9px;
            right: 30%;
            transform: rotate(135deg);
          }
        }
      }
    }
  }
}
