@import 'globalStyles/variables';

.popup {
  width: 624px;
}

.actions {
  display: flex;
  gap: $offset6;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $offset6;
  text-align: center;
  padding-top: $offset6;
}
