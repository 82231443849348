@import 'globalStyles/variables';

.container {
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  padding: $offset4;
  background: $whiteMaster;
  margin-bottom: $offset4;

  &:not(.done):hover {
    cursor: pointer;
    border: 1px solid $blue700;
    .title {
      color: $blue700;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: $offset4;
}

.title {
  width: 100%;
}

.description {
  margin-top: $offset3;
  padding-right: $offset14;
}
