@import 'globalStyles/variables';

.html-typography.html-typography-variant-primary {
  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  p + p {
    margin-top: $offset3;
  }

  ul,
  .checked-list {
    list-style-type: none;
    padding-left: $offset8;
    padding-top: $offset2;
    margin: 0;

    li,
    .checked-item {
      position: relative;
      margin-bottom: $offset2;

      &::before {
        content: '';
        position: absolute;
        background: $green700Master;
        width: 8px;
        height: 2px;
        transform: rotate(45deg);
        left: -$offset8;
        top: 50%;
      }

      &::after {
        content: '';
        position: absolute;
        background: $green700Master;
        width: 16px;
        height: 2px;
        transform: rotate(-45deg);
        left: -$offset8;
        margin-left: $offset1;
        top: 50%;
        margin-top: -2px;
      }
    }
    .unchecked-item {
      position: relative;
      margin-bottom: $offset2;

      &::before {
        content: '';
        background: url('../../../assets/img/uncheckedItem.svg') no-repeat
          center;
        position: absolute;
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        margin-left: 0;
        left: -$offset8;
        top: $offset1;
      }

      &::after {
        content: '';
        background: transparent;
      }
    }
  }
  .important {
    color: $red600Master;
  }
  .notes-block {
    // .typography-names.typography-Body1();
    border-top: 1px solid $blue20;
    padding-top: $offset4;
    color: $blackMaster;
  }
}
