@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.btn {
  display: flex;
  align-items: center;
  padding: 0 $offset4;
  border-right: 1px solid $blueSecondary;
  height: 39px;
  position: relative;

  &:hover {
    background-color: $blue700;
    cursor: pointer;
    height: 100%;
    border-right: 1px solid transparent;
  }

  &.active {
    background-color: $blue700;
    height: 100%;
    border-right: 1px solid transparent;
  }
}

.wrapper {
  position: fixed;
  padding: $offset4;
  top: $defaultHeaderHeight;
  padding-bottom: $defaultHeaderHeight;
  right: 0;
  box-shadow: $shadowSoft;
  border-left: 1px solid $blue20;
  z-index: 10;
  height: 100%;
  overflow-y: scroll;
  background-color: $whiteMaster;
  @include thinScrollbar();
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-icon {
  cursor: pointer;
}
