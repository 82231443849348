@import 'globalStyles/variables';

.range {
  display: flex;
  align-items: center;
  gap: $offset6;
  position: relative;

  .input {
    width: 368px;
  }

  .icon {
    margin-right: $offset2;
    cursor: pointer;
    position: relative;
  }
}
