@import 'globalStyles/variables';

.header {
  margin-bottom: $offset6;
}

.form-fields {
  width: 100%;
  margin-top: $offset8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-wrapper {
    width: 50%;
  }

  .badge {
    width: 50%;
  }

  .buttons {
    display: flex;
    gap: $offset4;

    .button-wrapper {
      margin-top: $offset4;
      width: 288px;
    }
  }
}
