@import 'globalStyles/variables';

.phone-codes {
  .phone-codes-item {
    display: flex;
    align-items: center;
    height: $largeFieldHeight;
    padding: 0 $offset2;

    &:hover {
      background-color: $grey50;
      cursor: pointer;
    }

    &.selected {
      background-color: $grey50;
    }

    b {
      font-weight: bold;
    }

    .phone-codes-country {
      margin-right: $offset2;
    }

    .phone-codes-selected-icon {
      margin-left: $offset2;
    }

    .phone-codes-flag {
      min-width: 20px;
      height: 11px;
      margin-right: $offset2;
    }
  }
}
