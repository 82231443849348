@import 'globalStyles/variables';

.menu {
  width: $defaultMenuWidth;
  height: 100%;
  border-left: 1px solid $blue20;
  border-right: 1px solid $blue20;
  background-color: $whiteMaster;
  position: fixed;
  z-index: 10;
}
