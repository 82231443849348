@import 'globalStyles/variables';

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &-left {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .radio-hidden {
    overflow: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
  }

  .radio-icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $whiteMaster;
  }

  .radio-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $whiteMaster;
    border-radius: $borderRadius8;
    margin-left: $offset3;
    border: 1px solid $grey400;
    min-width: 20px;
    min-height: 20px;
  }

  &.radio-left > .radio-box {
    margin-left: 0;
  }

  &.radio-checked {
    .radio-box {
      border-color: $blue700;
      background-color: $blue700;
    }

    @media (hover: hover) {
      &:hover {
        .radio-box {
          border-color: $blue300;
          background-color: $blue300;

          .radio-icon {
            background-color: $whiteMaster;
          }
        }
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      .radio-box {
        border-color: $blue700;

        .radio-icon {
          background-color: $blue300;
        }
      }
    }
  }
}
