@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.date-picker {
  width: 208px;
}

.revenue-per-day-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $offset6;
}

.diagram {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  margin-bottom: $offset6;
}

.diagram-header {
  display: flex;
  justify-content: space-between;
  padding: $offset4;
}
