@import 'globalStyles/variables';

.navigation {
  margin-top: $offset4;
  margin-bottom: $offset3;
}

.tabs {
  width: 100%;
  display: flex;
  gap: $offset3;
}

.sub-tabs {
  width: 100%;
  margin-top: $offset4;
  display: flex;
  gap: $offset3;
}

.item {
  background-color: $whiteMaster;
  padding: $offset2;
  width: fit-content;
  border: 1px solid $blue700;
  border-radius: $borderRadius4;
  cursor: pointer;

  &.active {
    border: 1px solid $blue20;
    background-color: $whiteMaster;
  }
}

.sub-item {
  padding-right: $offset4;
  border-right: 2px solid $grey200;
  cursor: pointer;

  &.active {
    border: none;
  }
}

.sub-item:last-child {
  border-right: none;
}
