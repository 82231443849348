@import 'globalStyles/variables';
.form-items-row {
  border-bottom: 1px solid $blue20;

  .form-item {
    width: 50%;
    margin-bottom: $offset6;
    padding-right: $offset2;
  }
}

.attachments {
  .attachments-header {
    padding-top: $offset6;
    display: flex;
    justify-content: space-between;

    .text {
      width: 468px;
    }

    .button {
      width: 150px;
    }
  }
}
