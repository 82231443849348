@import 'globalStyles/variables';

.phone-code {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  left: 1px;
  top: 1px;
  bottom: 1px;
  min-width: $offset20;

  .phone-code-country {
    background-color: $blue10;
    height: 100%;
    border-radius: $borderRadius4 0 0 $borderRadius4;
    border-right: 1px solid $grey200;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 $offset2;
    cursor: pointer;

    .phone-code-flag {
      width: 20px;
      height: 11px;
    }

    .phone-code-arrow {
      &.up {
        transform: rotate(180deg);
      }
    }

    .phone-code-number {
      margin: 0 $offset2;
    }
  }
}
