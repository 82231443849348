@import 'globalStyles/variables';

.item {
  display: flex;
  align-items: flex-end;
  margin-bottom: $offset4;

  .day {
    width: 100px;
    margin-right: $offset6;
    padding-bottom: $offset4;
  }

  .items {
    min-width: 420px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: $offset3;
    text-align: center;

    .form-item {
      width: 100%;
    }
  }
}
