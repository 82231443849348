@import 'globalStyles/variables';

.row {
  display: flex;
  gap: $offset6;

  &.bottom-offset {
    margin-bottom: $offset6;
  }
}
