@import 'globalStyles/variables';

$width: 100px;
$height: 40px;

.upload {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .delete-icon {
    cursor: pointer;
    margin-right: $offset4;
  }

  .upload-box {
    position: relative;
    width: $width;
    height: $height;
    cursor: pointer;
    border: 1px dashed $grey200;
    border-radius: $borderRadius6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $offset2;
    transition: border-color 300ms;
    background-color: $blue10;
    margin-right: $offset3;

    .label {
      display: flex;
      align-items: center;
    }

    &.disabled {
      background-color: $grey100;
      &:hover {
        border-color: $grey200;
      }
    }

    @media (hover: hover) {
      &:hover {
        border-color: $blue700;
      }
    }

    &.dragging {
      border-color: $blue700;
    }

    &.error {
      border-color: $red600Master;
    }
  }

  input {
    position: 'absolute';
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    cursor: default;
    display: none;
  }

  .upload-preview {
    width: $width;
    min-height: $height;
    overflow: hidden;
    margin-right: $offset3;
    border: 1px solid $grey200;
    border-radius: $borderRadius6;
    background-color: $whiteMaster;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .upload-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
