@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  gap: $offset4;

  .form-item {
    margin-bottom: $offset2;
    width: 50%;

    .phone {
      width: 100%;
    }
  }

  .text-area-field {
    width: 100%;
  }
}

.form-item-full {
  width: 100%;
}

.form-item-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-items-row:last-child {
  .form-item {
    padding-right: $offset2;
  }
}

.form-item-map-button {
  width: 200px;
  margin-bottom: $offset6;
}
