@import 'globalStyles/variables';

.field {
  height: $largeFieldHeight;
  background-color: $blue10;
  display: flex;
  align-items: center;
  padding: $offset2 $offset4;
  border-radius: $borderRadius4;
}
