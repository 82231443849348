@import 'globalStyles/variables';

.car-model-select {
  width: 100%;
}

.car-model-select-list-car {
  display: flex;
  align-items: center;
  gap: $offset2;
}

.car-model-select-footer {
  border-top: 1px solid $blue20;
  padding: $offset4 $offset5;
  display: flex;
  flex-direction: column;
  gap: $offset4;
}
