@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset4;

  .form-items-row {
    display: flex;
    gap: $offset4;

    .form-item {
      width: 100%;
    }
  }

  .form-items-row-half {
    width: calc(50% - $offset2);
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;

  .button-wrapper {
    width: 200px;

    &:last-child {
      margin-left: $offset4;
    }
  }
}

.radio-buttons {
  display: flex;
  gap: $offset4;
}
