@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  gap: $offset6;
}

.form-item {
  width: 50%;
  padding-right: $offset2;
}

.status-block {
  display: flex;
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.radio-button {
  display: inline-flex;
  gap: $offset6;
}

.connection-type-title {
  margin-bottom: $offset4;
}
