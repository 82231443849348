@import 'globalStyles/variables';

.subtitle {
  margin: $offset6 0;
}

.insurance {
  display: flex;
  align-items: center;
  gap: $offset2;
}

.insurance-tooltip {
  width: 222px;
}
