@import 'globalStyles/variables';

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .list-item,
  .current-item {
    display: flex;
    align-items: center;
  }

  .link {
    margin-right: $offset1;
  }

  .svg-arrow {
    margin-right: $offset1;
  }
}
