@import 'globalStyles/variables';

.form-items-row {
  display: flex;

  .form-item {
    width: 100%;

    .select-list-item-content {
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: $offset2;
      transition: background-color 300ms;

      .flag {
        margin-right: $offset2;
        border-radius: $borderRadius1;
      }
    }
  }

  .form-item:nth-child(2) {
    margin-left: $offset6;
  }
}

.form-items-row:last-child {
  .form-item:nth-child(3) {
    margin-left: $offset3;
  }
}
