@import 'globalStyles/variables';

.content {
  width: 576px;

  .description {
    padding: $offset6 0;
    text-align: center;
  }

  .buttons {
    display: flex;
    gap: $offset6;

    .button-wrapper {
      width: 100%;
      background-color: $blue700;
      transition: background 0.3s;
      border-radius: $borderRadius4;

      &:hover {
        background: $blue500;
      }

      .import {
        width: 0px;
        height: 0px;
        position: absolute;
      }

      .import + label {
        color: $whiteMaster;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue10;
  border-radius: $borderRadius4;
  width: 108px;

  &.disabled {
    background-color: $grey200;
    .import + label {
      background-color: $grey200;
      cursor: not-allowed;
    }
  }
}
