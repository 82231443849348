@import 'globalStyles/variables';
@import 'globalStyles/typographyStyles';

.value-input {
  width: 100%;
  border: 1px solid $blue700;
  margin: 0 auto;
  display: block;
  border-radius: $borderRadius2;
  max-width: 60px;
}

.value {
  text-align: center;
}
