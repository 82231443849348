@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  gap: $offset6;

  .form-item {
    width: 100%;
    margin-bottom: $offset4;
  }
}
