@import 'globalStyles/variables';

.select-list {
  overflow-y: auto;
  max-height: 258px;
  padding: $offset1;
  list-style-type: none;

  .select-list-item {
    cursor: pointer;
    height: 48px;
    padding: $offset1 0;

    &.selected,
    &:hover {
      .select-list-item-content {
        background-color: $blue10;
      }
    }

    .select-list-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding-left: $offset4;
      padding-right: $offset4;
      border-radius: $borderRadius4;
      transition: background-color 300ms;

      .select-list-icon {
        margin-left: $offset2;
        margin-right: $offset2;
      }
    }
  }
}

.select-list::-webkit-scrollbar {
  width: 4px;
  background-color: $whiteMaster;
}

.select-list::-webkit-scrollbar-thumb {
  background-color: $grey300;
  border-radius: $borderRadius10;
}

.select-list-search {
  background: $whiteMaster;
  padding: $offset2;
  border-bottom: 1px solid $blue20;
}

.not-found {
  background: $blue10;
  border: 1px solid $blue20;
  margin: $offset2;
  padding: $offset2 $offset3;
  border-radius: $borderRadius4;
}
