@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset6;

  .form-items-row {
    display: flex;
    gap: $offset4;

    .form-item {
      margin-bottom: $offset4;
      width: 100%;
    }
  }

  .price-list-type-select {
    width: 50%;
    margin-bottom: $offset6;
  }

  .price-list-type-badge {
    margin-bottom: $offset6;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;
  gap: $offset4;

  .button-wrapper {
    width: 200px;

    &:last-child {
      width: auto;
    }
  }
}
