@import 'globalStyles/variables';

.item-wrapper {
  border-bottom: 1px solid $grey200;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }

  .item {
    display: flex;
    height: 48px;
    align-items: center;
    width: 260px;

    &:last-child {
      margin-bottom: 0;
    }

    .image {
      margin-right: $offset3;
      width: 54px;
      height: 34px;
    }
  }
}
