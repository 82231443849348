@import 'globalStyles/variables';

.title {
  margin-bottom: $offset3;
}

.list {
  list-style: disc;
  padding-left: $offset5;
}
