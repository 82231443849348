@import 'globalStyles/variables';

.description {
  margin-top: $offset3;
  margin-left: $offset7;
}

.radio-btn {
  & + .radio-btn {
    margin-top: $offset6;
  }
}
