@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.autocomplete-wrapper {
  width: 240px;
}

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: flex-end;
    padding: $offset3 $offset4;
    height: 100%;
    border-bottom: 1px solid $blue20;
  }

  .table {
    width: 100%;

    .thead {
      border-radius: $offset3 $offset3 0 0;
      overflow: hidden;
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}

.email-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
