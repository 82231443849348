@import 'globalStyles/variables';

.title {
  white-space: pre-line;
  margin-top: $offset10;
}

.subtitle {
  margin-top: $offset2;
}
