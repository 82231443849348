@import 'globalStyles/variables';

.wrapper {
  width: 100%;
  margin-bottom: $offset6;
}

.duplicate-button-wrapper {
  width: 124px;
}

.title {
  display: flex;
  align-items: center;
  gap: $offset3;
}

.profile-details-form-wrapper {
  background-color: $blue10;
  padding: $offset6;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;

  .button-wrapper {
    width: 200px;

    &:last-child {
      margin-left: $offset4;
    }
  }
}
