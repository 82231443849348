@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset4;

  .form-items-row {
    display: flex;
    gap: $offset4;

    .form-item {
      margin-bottom: $offset2;
      width: 50%;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;

  .button-wrapper {
    width: 200px;

    &:last-child {
      margin-left: $offset4;
    }
  }
}

.container {
  display: flex;
  gap: $offset6;
}

.info {
  margin-bottom: $offset6;
  background: $blue10;
  border-radius: $borderRadius4;
  padding: $offset3 $offset4;
  display: flex;
  align-items: center;
  gap: $offset2;
}
