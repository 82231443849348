@import 'globalStyles/variables';

.menu-item {
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: $offset6;
  padding-left: $offset10;
  justify-content: space-between;
  position: relative;
  transition: opacity 0.5s;

  &:hover {
    text-decoration: none;
  }

  .menu-item-label {
    display: flex;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.active {
    background-color: $blue10;
    border-right: 2px solid $blue700;
  }

  &.with-submenu {
    border: 0;
    background-color: $whiteMaster;

    .arrow-icon {
      position: absolute;
      right: 20px;

      &.submenu-opened {
        transform: rotate(180deg);
      }
    }
  }

  .title {
    margin-left: $offset2;
  }
}
.submenu {
  position: relative;
  bottom: 0;
}
