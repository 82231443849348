@import 'globalStyles/variables';

.multiselect {
  .multiselect-inner {
    background: $whiteMaster;
    position: relative;
  }

  .multiselect-block {
    width: 100%;
    position: absolute;
  }

  .skeleton {
    display: flex;
    justify-content: center;
    padding: $offset8;
  }

  .multiselect-items {
    margin-top: $offset1;
    border-radius: $borderRadius4;
    border: 1px solid $blue20;
    position: relative;
    z-index: 10;
    width: 100%;
    background-color: $whiteMaster;

    .multiselect-search {
      padding: $offset4;
      border-bottom: 1px solid $blue20;
    }

    .multiselect-dropdown {
      overflow: auto;
      padding: $offset1 $offset2;
      min-height: 40px;
      max-height: 328px;
      box-shadow: $shadowSoft;
    }

    &.secondary {
      max-height: 110px;
    }
  }

  .multiselect-all-selected {
    padding: $offset2 0;
    border-bottom: 1px solid $blue20;
  }

  .multiselect-chips {
    display: flex;
    gap: $offset2;
  }

  &-error {
    &-space {
      &-default {
        padding-bottom: $offset8;
      }
    }
  }

  .not-found {
    background: $blue10;
    border: 1px solid $blue20;
    margin: $offset3 $offset2;
    padding: $offset2 $offset3;
    border-radius: $borderRadius4;
  }
}
