@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.autocomplete-wrapper {
  width: 240px;
}

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: flex-end;
    gap: $offset4;
    padding: $offset3 $offset4;
    height: 100%;
    border-bottom: 1px solid $blue20;

    .button {
      margin-right: $offset3;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue10;
      border-radius: $borderRadius4;
      width: 124px;
    }

    .download-link {
      border-left: 1px solid $blue20;
      padding-left: $offset4;
    }
  }

  .table {
    width: 100%;

    .company-id {
      display: flex;
      align-items: center;
      gap: $offset2;
    }

    .action-items {
      display: flex;
      align-items: center;
      gap: $offset5;
      justify-content: center;

      & > svg:hover {
        path:first-child {
          fill: $blue700;
        }
        path:last-child {
          stroke: $whiteMaster;
        }
      }
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}

.status {
  padding: $offset1 $offset2;
  border-radius: $borderRadius2;

  &.Unconfirmed {
    background-color: $grey50;
  }

  &.Registered,
  &.Approved {
    background-color: $green50;
    color: $green700Master;
  }

  &.Pending {
    background-color: $yellow50;
    color: $yellow900;
  }
}
