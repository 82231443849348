@import 'globalStyles/variables';

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  color: white;
  fill: currentColor;
  transition: fill 400ms;
  user-select: none;

  &.svg-icon-large {
    font-size: 24px;
  }

  &.svg-icon-medium {
    font-size: 20px;
  }

  &.svg-icon-small {
    font-size: 16px;
  }

  &.svg-icon-color-red600Master {
    color: $red600Master;
  }

  &.svg-icon-color-grey400 {
    color: $grey400;
  }

  &.svg-icon-color-yellow400Master {
    color: $yellow400Master;
  }

  &.svg-icon-color-blue700 {
    color: $blue700;
  }

  &.svg-icon-color-whiteMaster {
    color: $whiteMaster;
  }

  &.svg-icon-color-blackMaster {
    color: $blackMaster;
  }

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
