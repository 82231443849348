@import 'globalStyles/variables';

.contact-item-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.contact-item-info-icon {
  margin-top: calc($offset1 / 2);
  margin-right: $offset3;
}

.contact-item-info-value {
  margin-left: $offset1;
}

.contact-item-info-block {
  word-break: break-word;
}

.filter-map-img {
  border-radius: $borderRadius6;
  border: 1px solid $whiteMaster;
  overflow: hidden;
  height: 96px;
  margin-bottom: $offset6;
}
