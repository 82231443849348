@import 'globalStyles/mixins';

.heading-wrapper {
  display: flex;
  align-items: center;

  .heading {
    margin-right: 20px;
  }
}

.form {
  margin-bottom: $offset6;

  .archive-button-wrapper {
    width: 200px;
    margin-left: auto;
  }
}

.right-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $offset6;
}
