@import 'globalStyles/mixins';

.container {
  width: 100%;

  .header {
    display: flex;
    min-height: 78px;
    flex-direction: column;
    padding: $offset3 $offset6;
    justify-content: center;
    background-color: $blue10;
    border-bottom: 1px solid $blue20;

    .breadcrumbs {
      @include breadcrumbs();

      &.bottom-offset {
        margin-bottom: $offset3;
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;
        width: 100%;

        .tooltip-wrapper {
          margin-left: $offset2;

          .popup-content {
            width: 268px;
            height: 62px;
          }
        }
      }

      .company-badge {
        margin-left: $offset3;
        background-color: $whiteMaster;
        padding: 0 $offset2;
        white-space: pre;
        border-radius: $borderRadius2;
      }
    }
  }

  .content {
    padding: $offset6;
  }

  .loader {
    display: flex;
    margin-top: $offset40;
    justify-content: center;
  }
}

.status-badge {
  margin-left: $offset3;
}
