@import 'globalStyles/variables';

.content {
  width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $offset6 $offset6 0;

  .image {
    margin-top: $offset6;
  }

  .button-wrapper {
    width: 100%;
    margin-top: $offset4;
  }
}
