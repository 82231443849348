@import 'globalStyles/variables';

$mediumHeaderHeight: 64px;
$largeHeaderHeight: 78px;

.side-modal {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;

  &.header-size {
    &-medium {
      top: $mediumHeaderHeight;
    }

    &-large {
      top: $largeHeaderHeight;
    }
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-wrapper {
  position: absolute;
  width: auto;
  min-width: 400px;
  background: $whiteMaster;
  border: 1px solid $blue20;
  box-shadow: $shadowSoft;

  &.header-size {
    &-medium {
      height: calc(100vh - $mediumHeaderHeight);
    }

    &-large {
      height: calc(100vh - $largeHeaderHeight);
    }
  }

  &.position {
    &-right {
      right: 0;
    }
    &-left {
      left: 0;
    }
  }

  .modal-side-inner {
    position: relative;
    height: 100%;

    .modal-side-footer {
      position: absolute;
      bottom: 0;
      height: 84px;
    }
  }

  .modal-side-header {
    display: flex;
    width: 100%;
    gap: $offset6;
    padding: $offset4;
  }

  .modal-side-header-left {
    min-width: calc(100% - $offset10);
  }

  .modal-side-header-right {
    min-width: 24px;
    cursor: pointer;
  }

  .modal-side-content {
    height: 100%;
  }
}
