@import 'globalStyles/variables';
@import 'globalStyles/typographyMixins';
@import 'globalStyles/mixins';

$size: 16px;

.filter {
  position: relative;
  display: flex;
  justify-content: center;
  height: $size;
  cursor: pointer;

  .filter-icon {
    width: $size;
    height: $size;
    position: relative;
    margin-left: $offset2;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter-active {
      & > path {
        fill: $blue700;
      }
    }

    .selected {
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: $red600Master;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.dropdown-wrapper {
  position: fixed;
  z-index: 10;
}

.list-item {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  border: 1px solid $grey100;
  box-shadow: $shadowSoft;
  background-color: $whiteMaster;
  border-radius: $borderRadius4;

  &.only-sorting {
    padding-bottom: 0;
  }

  .sort-buttons {
    display: flex;
    flex-direction: column;
    padding: $offset4;
    border-bottom: 1px solid $grey100;

    .sort-button {
      display: flex;
      gap: $offset2;
      align-items: center;
      height: 40px;
      cursor: pointer;
    }
  }

  &.checked {
    background-color: $blue10;
  }

  .select-all {
    padding: 0 $offset4;
    height: 40px;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey100;

    &:hover {
      background-color: $grey50;
    }

    .checkbox-wrapper {
      display: flex;
      height: 48px;
      align-items: center;

      .checkbox-content {
        margin-right: $offset3;
      }
    }
  }

  .reset-button {
    margin: $offset1 0;
    padding: 0 $offset5;
    height: 40px;
    gap: $offset2;
    display: flex;
    white-space: nowrap;
    align-items: center;
    border-top: 1px solid $grey100;
    cursor: pointer;

    &:hover {
      background-color: $grey50;
    }
  }

  .search {
    padding: $offset4;
    border-bottom: 1px solid $blue20;
    width: 100%;
  }

  .filter-items {
    .not-found {
      margin: $offset4;
      border: 1px solid $blue20;
      background-color: $blue10;
      height: $mediumFieldHeight;
      display: flex;
      align-items: center;
      padding-left: $offset3;
      border-radius: $borderRadius4;
    }
  }
}
