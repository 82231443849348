@import 'globalStyles/variables';

.badge {
  border-radius: $borderRadius4;
  padding: $offset2 $offset4;
  display: flex;
  align-items: center;

  &.error {
    background-color: $red50;
    align-items: center;
  }

  &.info {
    background-color: $blue10;
  }

  &.warning {
    background-color: $yellow50;
    border-radius: $borderRadius4;
  }

  .badge-icon {
    margin-right: $offset2;
  }

  &.label {
    background-color: $blue10;
    padding: $offset1 $offset2;
  }
}
