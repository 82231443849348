@import 'globalStyles/variables';

.container {
  width: 100%;
  padding: $offset6;
  display: flex;
  gap: $offset6;
}

.left-block {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  min-height: 100%;
}

.right-block {
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  min-width: 308px;
  background: $blue10;
  padding: $offset6;
}

.title {
  padding-bottom: $offset6;
}
