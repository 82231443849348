@import 'globalStyles/variables';

.tooltip {
  display: flex;
  cursor: pointer;
  vertical-align: sub;

  &.start {
    align-self: flex-start;
  }

  &.center {
    align-self: center;
  }
}

.tooltip-content {
  padding: $offset3;

  &.primary {
    width: 276px;
  }

  &.secondary {
    width: 394px;
  }

  &.auto {
    max-width: 276px;
    width: auto;
  }
}
