@import 'globalStyles/variables';

.select-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  background-color: $whiteMaster;
  border: 1px solid $grey50;
  box-shadow: $shadowDropMedium;
  border-radius: $borderRadius6;
  margin-top: -$offset7;
  z-index: 10;

  &.without-error {
    margin-top: $offset1;
  }
}

.skeleton {
  display: flex;
  justify-content: center;
  padding: $offset8;
}
