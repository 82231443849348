@import 'globalStyles/variables';

.date-picker {
  width: 208px;
}

.tab-children {
  padding-top: $offset6;
  background-color: $whiteMaster;
}

.tab-content {
  display: flex;
  gap: $offset2;
  align-self: center;
  height: 100%;

  .counter {
    min-width: 20px;
    height: 20px;
    padding: 0 $offset1;
    background-color: $blue50;
    display: flex;
    border-radius: $borderRadius12;
    justify-content: center;
    align-items: center;
  }
}
