@import 'globalStyles/variables';

.notifications {
  display: flex;
  flex-direction: column;
  gap: $offset4;
  margin-top: $offset6;
  width: 356px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $offset4;
}
