@import 'globalStyles/variables';

.vehicle-item-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.vehicle-item-info-icon {
  margin-top: calc($offset1 / 2);
  margin-right: $offset3;
}

.vehicle-item-info-value {
  margin-left: $offset1;
}

.vehicle-item-info-block {
  word-break: break-word;
}
