@import 'globalStyles/variables';

.additional-equipment {
  display: flex;
  align-items: center;
  gap: $offset2;
}

.additional-equipment-tooltip {
  width: 222px;
}
