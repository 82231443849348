@import 'globalStyles/variables';

.container {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  background: $blue10;
  padding: $offset6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: $offset4;
}

.button-wrapper-short {
  width: 122px;
}

.car-image-wrapper {
  width: 100%;
  height: 132px;
  background-color: $whiteMaster;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius6;
  border: 1px solid $blue20;
  margin-bottom: $offset4;
}

.title {
  margin-bottom: $offset4;
  display: block;
}
