@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.table {
  border: 1px solid $blue20;
  margin-top: $offset6;
  border-radius: $borderRadius6;
  position: relative;
  overflow: hidden;
}

.info-table-total {
  display: flex;
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  margin-top: $offset6;
}

.info-table-header-item {
  padding: $offset2 $offset4;
  background: $blue10;
  width: 100%;
  padding: 0;
  border-right: 1px solid $blue20;
}

.location-block {
  margin-top: $offset6;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location-btn {
  width: 200px;
}

.location-title {
  display: flex;
  flex-direction: column;
  gap: $offset1;
}
