@import 'globalStyles/variables';
@import 'globalStyles/typographyStyles';

.editable-cell {
  width: 60px;
}

.editable-cell-block {
  max-width: 160px;
}

.editable-cell-value {
  text-align: start;
  min-width: 60px;
  height: 25px;
  display: flex;
  align-items: center;
}
