@import 'globalStyles/variables';

.button-block {
  margin-top: $offset6;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button-wrapper {
  width: 200px;
}

.radio-button {
  display: flex;
  justify-content: flex-end;
  gap: $offset4;
}
