@import 'globalStyles/variables';

.checkbox {
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  .checkbox-hidden {
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    overflow: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
  }

  .checkbox-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $whiteMaster;
    border-radius: $borderRadius1;
    border: 1px solid;
  }

  @media (hover: hover) {
    &:hover:not(.checkbox-variant-default) {
      .checkbox-icon {
        background: $grey300;
      }
    }
  }

  .checkbox-icon {
    visibility: hidden;
    display: flex;
  }

  &-checked {
    .checkbox-icon {
      visibility: visible;
    }
  }

  &-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .checkbox-box.checkbox-box-error {
    border-color: $red600Master;
  }

  &-size {
    &-default {
      .checkbox-box {
        min-width: 16px;
        height: 16px;
      }
    }
  }
}

.checkbox-variant {
  &-default {
    .checkbox-box {
      &.checkbox-checked {
        background-color: $blue700;
        border: none;
      }
    }

    .checkbox-icon {
      background: url('../../../assets/img/CheckIcon.svg');
      width: 10px;
      height: 8px;
    }
  }

  &-secondary {
    .checkbox-icon {
      width: 6px;
      height: 1.5px;
      background: $blackMaster;
      border-radius: $borderRadius1;
    }
  }

  &-primary {
    .checkbox-icon {
      width: 7px;
      height: 7px;
      background: $blackMaster;
      border-radius: $borderRadius1;
    }
  }

  &-border {
    &-greyBold {
      .checkbox-box {
        border: 2px solid $grey400;
      }
    }

    &-yellow {
      .checkbox-box {
        border-color: $yellow600;

        @media (hover: hover) {
          &:hover {
            border-color: $yellow300;
          }
        }
      }
    }

    &-grey {
      .checkbox-box {
        border-color: $grey200;
      }
    }
  }
}
