@import 'globalStyles/variables';

.form-items-row {
  display: flex;
  margin-bottom: $offset2;
  gap: $offset4;

  .form-item {
    max-width: calc(50% - $offset2);
  }

  .form-item:last-child {
    margin-right: 0;
  }
}

.form-item-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
