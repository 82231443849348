@import 'globalStyles/mixins';

.list {
  @include listWrapper();

  .trash-icon {
    width: 100%;
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $offset3;
    border-bottom: 1px solid $blue20;
    gap: $offset4;

    .buttons {
      display: flex;

      .filter-icon {
        border-left: 1px solid $blue20;
        padding-left: $offset4;
        display: flex;
        align-items: center;
      }

      .button {
        margin-right: $offset3;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue10;
        border-radius: $borderRadius4;
        height: 32px;
        width: 140px;
      }
    }
  }

  .table {
    width: 100%;

    .vehicle {
      display: flex;
      align-items: center;

      .image {
        margin-right: $offset2;
        width: 52px;
        height: 34px;
      }
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}

.prices-button {
  width: 40px;
}
