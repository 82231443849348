@import 'globalStyles/variables';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + .row {
    border-top: 1px solid $blue20;
    padding-top: $offset3;
    margin-top: $offset3;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: $offset6;
}

.cors {
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
