@import 'globalStyles/variables';

.reset-password {
  max-width: 720px;
  margin: $offset26 auto 0;

  .link {
    position: absolute;
    display: flex;
    top: 42px;

    .icon-link {
      display: flex;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
}
