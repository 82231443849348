@import 'globalStyles/variables';

.tooltip {
  width: 198px;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: $offset2;
  margin-bottom: $offset6;
}

.go-to-location {
  min-width: 140px;
  align-self: center;
  margin-top: -$offset2;
}

.requirements-title {
  margin: $offset4 0 $offset2;
}
