@import 'globalStyles/variables';

.multiselect-chip {
  height: 30px;
  padding: 0 $offset2;
  display: flex;
  align-items: center;
  background-color: $blue10;
  border-radius: $borderRadius4;

  .multiselect-chip-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $offset2;
    cursor: pointer;
  }
}
