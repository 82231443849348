@import 'globalStyles/variables';

.content {
  width: 100%;
  margin-top: $offset6;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: $offset2;
  }

  .image {
    width: 200px;
    height: 127px;
    margin-bottom: $offset4;
  }
}
