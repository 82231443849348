@import 'globalStyles/variables';

.layout {
  border-right: 1px solid $blue20;
  padding-top: $offset16;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  position: relative;

  .content {
    position: relative;
    left: $defaultMenuWidth;
    width: calc(100% - $defaultMenuWidth);
  }
}
