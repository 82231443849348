.carousel {
  overflow: hidden;
  .carousel-inner {
    overflow: hidden;

    .carousel-draggable.carousel-cursor {
      cursor: pointer;
      touch-action: none;

      &::after {
        content: '';
        clear: both;
        display: table;
      }
    }

    .carousel-dragging.carousel-cursor {
      cursor: grabbing;
    }

    .carousel-slide {
      overflow: hidden;
      float: left;
      height: 100%;
      min-height: 1px;

      img {
        pointer-events: none;
      }

      &-disable-events {
        pointer-events: none;
      }
    }
  }
}
