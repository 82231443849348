@import 'globalStyles/variables';

.submenu {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: $offset16;
  transition: opacity 0.5s;

  &.active {
    background-color: $blue10;
    border-right: 2px solid $blue700;
  }

  &.disabled {
    opacity: 0.2;
  }

  &:hover {
    cursor: pointer;
  }
}
