@import 'globalStyles/variables';

.signup-form {
  max-width: 720px;
  margin: $offset26 auto 0;

  .header {
    margin-bottom: $offset2;
  }

  .pincode {
    margin-top: $offset8;
    margin-bottom: $offset11;
  }

  .badge {
    margin-bottom: $offset4;
  }

  .button-wrapper {
    width: 288px;
  }
}
