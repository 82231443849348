@import 'globalStyles/variables';

.dropdown-items-item {
  padding: $offset3 $offset4;
  border-bottom: 1px solid $blue20;
  cursor: pointer;
}

.dropdown-items-item:last-child {
  border: none;
}

.dropdown-items-item-selected,
.dropdown-items-item:hover {
  color: $blue700;
  background: $blue10;

  &:first-child {
    border-radius: $borderRadius4 $borderRadius4 0 0;
  }
  &:last-child {
    border-radius: 0 0 $borderRadius4 $borderRadius4;
  }
}
