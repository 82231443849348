@import 'globalStyles/variables';
@import 'globalStyles/typographyStyles';

.wrapper {
  display: flex;

  .input-wrapper {
    width: 48px;
    height: 48px;
    margin-right: $offset4;
    border-radius: $borderRadius4;
    text-align: center;
    border: 1px solid $grey200;

    &:focus {
      border: 1px solid $blue700;
    }
  }
}
