@import 'globalStyles/variables';
@import 'globalStyles/typographyStyles';

.input {
  position: relative;
  width: auto;

  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }

  &-align {
    &-left {
      input {
        text-align: left;
      }
    }
  }

  &-border-grey {
    input {
      border: 1px solid $grey200;

      &:hover {
        border-color: $grey400;
      }

      &:focus {
        border-color: $blue700;
      }

      &:hover:read-only,
      &:focus:read-only {
        border-color: $grey200;
      }
    }
  }

  &-border-blue input {
    border: 1px solid $blue600;
  }

  &-border-yellow input {
    border: 1px solid $yellow600;
  }

  &-innerText {
    &-label {
      position: absolute;
      top: 4px;
      left: $offset2;
      right: $offset2;
      z-index: 2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  &-small {
    .input-inner {
      height: $smallFieldHeight;
    }

    input {
      height: $smallFieldHeight;
    }
  }

  &-medium {
    .input-inner {
      height: $mediumFieldHeight;
    }

    input {
      height: $mediumFieldHeight;
    }
  }

  &-large {
    .input-inner {
      height: $largeFieldHeight;
    }

    input {
      height: $largeFieldHeight;
    }
  }

  input {
    padding-right: $offset2;
    padding-left: $offset4;
    color: $blackMaster;
    border-radius: $borderRadius4;
    width: 100%;
    position: relative;

    &.readonly {
      background-color: $grey50;
    }

    &.input-left-offset-small {
      padding-left: $offset2;
    }

    &.disabled {
      background-color: $grey50;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .input-inner {
    position: relative;
    display: flex;
    align-items: center;

    &.input-clicked {
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }

    .input-svg-left,
    .input-svg-right {
      position: absolute;
      z-index: 2;
    }

    .input-svg-left {
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-with-icon {
    &-left {
      &.input-icon-space-medium {
        .input-svg-left {
          width: $offset10;
        }

        input {
          padding-left: $offset10;
        }
      }

      &.input-icon-space-small {
        .input-svg-left {
          width: $offset7;
        }

        input {
          padding-left: $offset7;
        }
      }
    }

    &-right {
      input {
        padding-right: $offset11;
      }
      .input-svg-right {
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-right: $offset4;
        padding-left: $offset2;
      }

      &.input-with-icon-right-click {
        .input-svg-right {
          cursor: pointer;
        }
      }
    }
  }

  &-error {
    &-space {
      &-default {
        padding-bottom: $offset8;
      }

      &-auto {
        &:not(.input-error) {
          padding-bottom: $offset8;
        }

        .input-error-label {
          min-height: 24px;
        }
      }
    }

    input {
      border-color: $red600Master;
      z-index: 1;
    }
  }

  &-no-border {
    &-all {
      input {
        border-width: 0;
        border-radius: 0;
      }
    }

    &-right {
      input {
        border-right-width: 0;
      }
    }
  }
}
.input-type-time {
  .input-inner {
    input {
      padding-right: $offset1;
      padding-left: $offset1;
      display: flex;
      text-align: center;
      justify-content: center;
    }
  }
}
