@import 'globalStyles/variables';

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $yellow700;
  border: 1px solid $blue900Master;
  margin-left: 5px;
  margin-top: -6px;
}
