// colors
$whiteMaster: #ffffff;
$blackMaster: #000000;

$green50: #eff9ea;
$green300: #a1da83;
$green400: #8cd267;
$green500: #77ca4b;
$green600: #68b943;
$green700Master: #54a539;
$green800: #40912f;

$coralDark: #afe9dd;
$greenLight: #d9fff8;

$yellow50: #fff7e1;
$yellow300: #ffd34f;
$yellow400Master: #ffc926;
$yellow600: #fab400;
$yellow700: #ffa201;
$yellow900: #ff6e00;
$yellowBorder: #ff9000;
$yellowDark: #fbdf98;
$yellowLight: #fef2d5;

$orangeDark: #ffd7c0;
$orangeLight: #fff5ef;

$grey50: #f5f5f5;
$grey100: #e9e9e9;
$grey150: #f5f9ff;
$grey200: #d9d9d9;
$grey300: #c4c4c4;
$grey400: #9d9d9d;
$grey450: #8e8e95;
$grey500: #7b7b7b;
$grey650: #332b0f;
$greyOpacity50: rgba(0, 0, 0, 0.5);
$grey900: #000000;
$greyBorder: #f5f5f5;

$blue10: #f5f9ff;
$blue20: #e7e7f2;
$blue50: #e3f3fe;
$blue300: #61b7fe;
$blue500: #1198ff;
$blue600: #148af2;
$blue700: #2468e5;
$blue900Master: #1048ad;
$blueSecondary: #97abd7;

$purpleDark: #e8daff;
$purpleLight: #fcf4ff;

$red50: #ffeaef;
$red600Master: #ff002e;

$pinkDark: #f6c8d7;
$pinkLight: #fcecf2;

// offsets
$offset1: 4px;
$offset2: 8px;
$offset3: 12px;
$offset4: 16px;
$offset5: 20px;
$offset6: 24px;
$offset7: 28px;
$offset8: 32px;
$offset9: 36px;
$offset10: 40px;
$offset11: 44px;
$offset12: 48px;
$offset13: 52px;
$offset14: 56px;
$offset15: 60px;
$offset16: 64px;
$offset17: 68px;
$offset18: 72px;
$offset19: 76px;
$offset20: 80px;
$offset21: 84px;
$offset22: 88px;
$offset23: 92px;
$offset24: 96px;
$offset25: 100px;
$offset26: 104px;
$offset27: 108px;
$offset28: 112px;
$offset29: 116px;
$offset30: 120px;
$offset31: 124px;
$offset32: 128px;
$offset33: 132px;
$offset34: 136px;
$offset35: 140px;
$offset36: 144px;
$offset37: 148px;
$offset38: 152px;
$offset39: 156px;
$offset40: 160px;
$offset41: 164px;
$offset42: 168px;
$offset43: 172px;
$offset44: 176px;
$offset45: 180px;
$offset46: 184px;
$offset47: 188px;
$offset48: 192px;
$offset49: 196px;
$offset50: 200px;
$offset51: 204px;
$offset52: 208px;
$offset53: 212px;
$offset54: 216px;
$offset55: 220px;
$offset56: 224px;
$offset57: 228px;
$offset58: 232px;
$offset59: 236px;

// breakpoints
$breakpointTablet: 1279px;
$smallBreakpointTablet: 1023px;
$breakpointMobile: 767px;
$smallBreakpointMobile: 575px;

// border-radius
$borderRadius1: 2px;
$borderRadius2: 4px;
$borderRadius3: 6px;
$borderRadius4: 8px;
$borderRadius5: 10px;
$borderRadius6: 12px;
$borderRadius7: 14px;
$borderRadius8: 16px;
$borderRadius9: 18px;
$borderRadius10: 20px;
$borderRadius11: 22px;
$borderRadius12: 24px;
$borderRadius13: 26px;
$borderRadius14: 28px;

// shadows
$shadowSoft: 0px 2px 16px rgba(0, 0, 0, 0.06);
$shadowDropMedium: 0px 16px 24px rgba(51, 51, 51, 0.08);

// sizes
$defaultHeaderHeight: 64px;
$desktopBtagHeaderHeight: 106px;
$largeFieldHeight: 48px;
$mediumFieldHeight: 40px;
$smallFieldHeight: 32px;
$mobileBannerHeight: 58px;
$mobileSearchHeaderHeight: 49px;
$defaultBreadcrumbsHeight: 32px;
$defaultMenuWidth: 296px;

// paddings
$defaultContentPadding: calc(#{$defaultBreadcrumbsHeight} + #{$offset8});
