@import 'globalStyles/variables';

.multiselect-items-block {
  .multiselect-items-item {
    padding: $offset3 $offset4;
    margin-top: $offset1;
    margin-bottom: $offset1;
    cursor: pointer;
  }

  .multiselect-items-item-selected,
  .multiselect-items-item:hover {
    color: $blue700;
    background: $blue10;
    border-radius: $borderRadius4;
  }
  &:last-child {
    border-bottom: none;
  }
}
