@import 'globalStyles/variables';

.wrapper {
  border-radius: $borderRadius6;
  border: 1px solid $blue20;
  box-shadow: $shadowSoft;
  background-color: $whiteMaster;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 $offset6;
    padding: $offset4 0;
    border-bottom: 1px solid $blue20;
  }

  .content {
    padding: $offset6;

    &-left {
      flex-direction: column;
      gap: $offset3;
      align-items: flex-start;
    }
  }
}
