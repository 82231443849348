@import 'globalStyles/variables';

.range {
  display: flex;
  gap: $offset2;
  align-items: center;

  .icon {
    margin-bottom: $offset2;
    cursor: pointer;
  }
}
