@import 'globalStyles/variables';

.block {
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  padding: $offset6;
  width: 100%;

  & + .block {
    margin-top: $offset6;
  }
}

.title {
  margin-bottom: $offset6;
}
