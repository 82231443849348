@import 'globalStyles/variables';

.navigation {
  min-width: 308px;
  max-width: 308px;
}

.item {
  padding: $offset4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $offset4;
  border-left: 1px solid $blue20;
  border-right: 1px solid $blue20;

  .arrow {
    transition: transform 0.3s;
    transform: rotate(-20deg);
  }

  &:first-child {
    border-radius: $borderRadius6 $borderRadius6 0 0;
    border-top: 1px solid $blue20;
  }

  &:last-child {
    border-radius: 0 0 $borderRadius6 $borderRadius6;
    border-bottom: 1px solid $blue20;
  }

  &:hover,
  &.active {
    background-color: $blue10;
    cursor: pointer;
    color: $blue700;

    .arrow {
      transform: rotate(0);
    }
  }
}
