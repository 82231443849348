@import 'globalStyles/variables';

.content {
  padding-top: $offset6;
  text-align: center;
  width: 576px;

  .buttons {
    display: flex;
    margin-top: $offset8;

    .button-wrapper {
      width: 100%;

      &:first-child {
        margin-right: $offset6;
      }
    }
  }
}
