@import 'globalStyles/variables';

.header {
  margin-bottom: $offset6;
}

.form-fields {
  width: 100%;
  margin-top: $offset8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .input-wrapper {
    width: 50%;
  }

  .badge {
    width: 50%;
  }

  .timer-label {
    height: $largeFieldHeight;
    width: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    display: flex;
    gap: $offset6;
    margin-top: $offset4;

    .button-wrapper {
      width: 288px;

      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
