@import './typographyMixins.scss';

.typography-Body1,
.Body1,
.highlight-Body1 mark {
  @include Body1;
}

.body2WMedium,
.highlight-body2WMedium mark {
  @include body2WMedium;
}

.body2WBold,
.highlight-body2WBold mark {
  @include body2WBold;
}

.H1,
.highlight-H1 mark {
  @include H1;
}

.H2,
.highlight-H2 mark {
  @include H2;
}

.H3,
.highlight-H3 mark {
  @include H3;
}

.H4,
.highlight-H4 mark {
  @include H4;
}

.H5,
.highlight-H5 mark {
  @include H5;
}

.typography-H6,
.H6,
.highlight-H6 mark {
  @include H6;
}

.Button1,
.highlight-Button1 mark {
  @include Button1;
}

.typography-Button2,
.Button2,
.highlight-Button2 mark,
.Button2Underline,
.Button2Underline mark {
  @include Button2;
}

.typography-Subtitle1,
.Subtitle1,
.highlight-Subtitle1 mark {
  @include Subtitle1;
}

.typography-Subtitle2,
.Subtitle2,
.highlight-Subtitle2 mark {
  @include Subtitle2;
}

.typography-Subtitle3,
.Subtitle3,
.highlight-Subtitle3 mark {
  @include Subtitle3;
}

.Subtitle4,
.highlight-Subtitle4 mark,
.Subtitle4Underline,
.Subtitle4Underline mark {
  @include Subtitle4;
}

.Subtitle5,
.highlight-Subtitle5 mark {
  @include Subtitle5;
}

.Subtitle6,
.highlight-Subtitle6 mark {
  @include Subtitle6;
}

.Subtitle7,
.highlight-Subtitle7 mark {
  @include Subtitle7;
}

.Button2Underline,
.Subtitle4Underline {
  @include Button2Underline;
}

.subtitleWBold,
.highlight-subtitleWBold mark {
  @include subtitleWBold;
}
