@import 'globalStyles/variables';

.driver-age-range {
  display: flex;
  margin-top: $offset4;
}

.driver-age-range-info-value {
  margin-left: $offset1;
}

.driver-age-range-block {
  margin-top: $offset3;
  margin-bottom: $offset4;
}

.driver-age-range-title {
  margin-bottom: $offset4;
}

.driver-age-range-info,
.driver-age-rangeinfo-item {
  width: 100%;
  display: block;
}
.driver-age-range-info {
  margin-left: $offset3;
  display: flex;
  flex-direction: column;
}
