@import 'globalStyles/variables';

.container {
  min-width: 524px;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  padding: $offset6;
}

.tab-children {
  margin-top: $offset6;
}

.tab-children-error {
  display: flex;
  align-items: center;
  gap: $offset2;
}
