@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.date-picker {
  width: 208px;
}

.diagram {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
}

.diagram-header {
  display: flex;
  justify-content: space-between;
  padding: $offset4;
}

.car-rental {
  margin-bottom: $offset6;
}
