@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset4;
}

.container {
  display: flex;
  gap: $offset6;
}

.info {
  margin-bottom: $offset6;
  background: $blue10;
  border-radius: $borderRadius4;
  padding: $offset3 $offset4;
  display: flex;
  align-items: center;
  gap: $offset2;
}
