@import 'globalStyles/variables';

.reservation-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
}

.reservation-info-icon {
  margin-top: calc($offset1 / 2);
  margin-right: $offset3;
}

.reservation-info-value {
  margin-left: $offset1;
}

.reservation-driver-block {
  margin-top: $offset3;
}

.reservation-title {
  margin-bottom: $offset4;
}

.reservation-driver-info,
.reservation-driver-info-item {
  width: 100%;
  display: block;
}
.reservation-driver-info {
  margin-left: $offset3;
}

.reservation-driver {
  display: flex;
  margin-top: $offset4;
}
