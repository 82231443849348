@import 'globalStyles/variables';

.carousel-dots {
  display: flex;
  margin-top: $offset8;
  justify-content: center;
}

.carousel-dot {
  height: 3px;
  background: $grey200;
  width: 100%;
  max-width: 28px;
  cursor: pointer;
  border-radius: $borderRadius2;

  &-active {
    background: $yellow400Master;
  }
}

.carousel-dot + .carousel-dot {
  margin-left: $offset6;
}
