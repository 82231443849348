@import 'globalStyles/variables';

.tip {
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  padding: $offset4;
  display: flex;
  flex-direction: column;
  gap: $offset3;
  margin-bottom: $offset4;

  .topic {
    background-color: $blue50;
    border-radius: $borderRadius2;
    width: fit-content;
    padding: 0 $offset2;
  }

  .title {
    margin: $offset3 0;
  }
}
