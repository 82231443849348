@import 'globalStyles/variables';

.form-items-row {
  display: flex;

  .form-item {
    margin-bottom: $offset2;
    width: calc(50% - $offset2);
  }

  .form-item-left {
    margin-right: $offset4;
  }
}

.driver-age-range-header {
  margin-bottom: $offset4;
  padding-bottom: $offset4;
  border-bottom: 1px solid $blue20;
}

.form-tooltip-wrapper {
  position: relative;

  .form-tooltip {
    position: relative;
    justify-content: flex-end;
  }
}
