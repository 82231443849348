@import 'globalStyles/variables';

.container {
  width: 100%;
  padding: $offset6;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  margin-bottom: $offset1;
}

.title-description {
  padding-bottom: $offset2;
}

.sub-title {
  margin-top: $offset4;
  margin-bottom: $offset1;
}
