@import './variables';

.whiteMaster,
.highlight-whiteMaster mark {
  color: $whiteMaster;
}

.blackMaster,
.highlight-blackMaster mark {
  color: $blackMaster;
}

.yellow400Master,
.highlight-yellow400Master mark {
  color: $yellow400Master;
}

.yellow700,
.highlight-yellow700 mark {
  color: $yellow700;
}

.grey400,
.highlight-grey400 mark {
  color: $grey400;
}

.grey450,
.highlight-grey450 mark {
  color: $grey450;
}

.grey500,
.highlight-grey500 mark {
  color: $grey500;
}

.grey650,
.highlight-grey650 mark {
  color: $grey650;
}

.grey900,
.highlight-grey650 mark {
  color: $grey900;
}

.blue700,
.highlight-blue700 mark {
  color: $blue700;
}

.blue900Master,
.highlight-blue900Master mark {
  color: $blue900Master;
}

.red600Master,
.highlight-red600Master mark {
  color: $red600Master;
}

.green600,
.highlight-green700Master mark {
  color: $green600;
}

.green600,
.highlight-green600 mark {
  color: $green600;
}

.green700Master,
.highlight-green700Master mark {
  color: $green700Master;
}

.blueSecondary,
.highlight-blueSecondary mark {
  color: $blueSecondary;
}

.yellow900,
.highlight-yellow900 mark {
  color: $yellow900;
}
