@import 'globalStyles/variables';

.dropdown {
  position: relative;
  z-index: 1;

  &-items {
    margin-top: $offset1;
    border-radius: $borderRadius4;
    border: 1px solid $blue20;
    position: absolute;
    width: 100%;
    background-color: $whiteMaster;
  }
}
