@import 'globalStyles/variables';

.header {
  margin-bottom: $offset6;
}

.form-fields {
  width: 100%;
  margin-top: $offset8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form {
    display: flex;
    gap: $offset4;

    .form-item {
      width: 100%;
    }
  }

  .button-wrapper {
    margin-top: $offset4;
    width: 288px;
  }
}

.new-password-label {
  white-space: nowrap;
}
