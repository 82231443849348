@import 'globalStyles/variables';

.popup {
  position: fixed;
  background: $whiteMaster;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  z-index: 999;

  .popup-arrow {
    position: fixed;
    width: 17px;
    height: 8px;
    z-index: 999;
    background-image: url(../../../assets/img/modalArrow.svg);
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $grey900;
  opacity: 0.25;
  z-index: 998;
}
