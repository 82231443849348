@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.autocomplete-wrapper {
  width: 240px;
}

.refresh-icon {
  width: 100%;
  display: flex;
  align-items: center;
}

.list {
  @include listWrapper();

  .table {
    width: 100%;

    .company-id {
      display: flex;
      align-items: center;
      gap: $offset2;
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
