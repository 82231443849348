@import 'globalStyles/variables';

.button-wrapper {
  width: 200px;
  margin: 0 auto;
}

.badge {
  margin-bottom: $offset6;
}
