@import 'globalStyles/variables';

.button {
  margin-right: $offset4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue10;
  border-radius: $borderRadius4;
  width: 144px;

  &.disabled {
    background-color: $grey200;
  }
}

.content {
  width: 576px;
  margin-top: $offset5;
  display: flex;
  flex-direction: column;
  gap: $offset6;
}

.modal-info-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-info-not-found-title {
  margin-top: $offset4;
  margin-bottom: $offset2;
}

.modal-info-not-found {
  height: calc(100% - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-info-footer {
  background: $whiteMaster;
  position: relative;
  width: 100%;
  height: 80px;
  padding: $offset4;
  bottom: 0;
  border-top: 1px solid $blue20;
  box-shadow: $shadowSoft;
}

.modal-info-search {
  background: $whiteMaster;
  width: 100%;
  position: relative;
  height: 80px;
  padding: $offset4;
  border-bottom: 1px solid $blue20;
  &.checked {
    height: 132px;
    padding-bottom: 0;
  }
}

.modal-info-body {
  position: relative;
  height: calc(100% - 232px);
  width: 100%;
  overflow-x: scroll;
  padding-left: $offset4;
  padding-right: $offset2;
  &.checked {
    height: calc(100% - 284px);
  }
}

.modal-info-list-item {
  min-height: 48px;
  padding: $offset2 $offset3;
}

.modal-info-list-item-title {
  margin-left: $offset1;
  cursor: pointer;
}

.modal-info-list-item-top {
  min-height: 48px;
  padding: $offset2 $offset3;
  margin: $offset2 0;
  border-bottom: 1px solid $blue20;
}

.modal-info-tips {
  display: flex;
  gap: $offset2;
  margin-top: $offset4;
  margin-bottom: $offset4;
}
