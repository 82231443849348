@import 'globalStyles/mixins';

.table {
  @include listWrapper();
  width: 100%;

  .tables {
    overflow-x: hidden;
  }
}
