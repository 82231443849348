@import 'globalStyles/mixins';

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $offset3 $offset4;
    border-bottom: 1px solid $blue20;
    gap: $offset4;

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue10;
      border-radius: $borderRadius4;
      width: 148px;
      height: 32px;
    }

    .filter-icon {
      padding-left: $offset4;
      border-left: 1px solid $blue20;
    }
  }

  .table {
    width: 100%;
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
