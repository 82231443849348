@import 'globalStyles/variables';

.table {
  border: 1px solid $blue20;
  margin-top: $offset6;
  border-radius: $borderRadius6;
  position: relative;
  overflow: hidden;
}

.vehicle-block {
  margin-top: $offset6;
}

.vehicle-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.complete-btn {
  width: 200px;
}

.vehicle-title {
  display: flex;
  flex-direction: column;
  gap: $offset1;
}

.vehicle {
  display: flex;
  align-items: center;

  .image {
    margin-right: $offset2;
    min-width: 52px;
    width: 52px;
    height: 34px;
  }
}
