@import 'globalStyles/variables';

.btns {
  display: flex;
  gap: $offset4;
}

.btn {
  width: 124px;
}

.offset {
  margin-top: $offset6;
}
