@import 'globalStyles/variables';

.form {
  width: 100%;
  margin-bottom: $offset4;

  .details-block {
    padding-bottom: $offset6;
    border-bottom: 1px solid $blue20;
    margin-bottom: $offset6;
  }

  .form-items-row {
    display: flex;
    margin-bottom: $offset8;

    .form-item {
      width: 100%;
    }

    .form-item:nth-child(2) {
      margin-left: $offset4;
    }
  }

  .form-items-row:last-child {
    .form-item:nth-child(3) {
      margin-left: $offset3;
    }
  }
}

.radio-buttons {
  display: flex;
  gap: $offset4;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $offset3;

  .button-wrapper {
    width: 200px;

    &:last-child {
      margin-left: $offset4;
    }
  }
}
