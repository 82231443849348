@import 'globalStyles/variables';

.container {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggle {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 28px;
    margin-left: $offset2;
    height: 16px;
    border-radius: $borderRadius6;
    background-color: $grey200;

    &.active {
      background-color: $blue700;
    }

    .switch {
      display: block;
      width: 12px;
      height: 12px;
      margin: 2px;
      background: $whiteMaster;
      position: absolute;
      cursor: pointer;
      border-radius: $borderRadius10;
      transition: right 0.3s;

      &.active {
        right: 0px;
      }
    }
  }
}
