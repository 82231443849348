@import 'globalStyles/variables';

.content {
  display: flex;
  justify-content: center;

  .button-wrapper {
    width: 24px;
    height: 24px;
    background-color: $blue10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius2;

    &.disabled {
      background: $grey100;
      cursor: not-allowed;
      color: $grey500;
    }

    &:hover:not(.disabled) {
      border: 1px solid $blue700;
      cursor: pointer;
    }
  }
}
