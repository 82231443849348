@import 'globalStyles/variables';

.modal-info-tip {
  max-width: 180px;
  padding: $offset2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $blue10;
  border-radius: $borderRadius4;
}

.modal-info-tip-title {
  max-width: 140px;
  margin-right: $offset2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-side-tip-close {
  cursor: pointer;
}
