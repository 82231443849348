@import 'globalStyles/variables';

$mediumHeaderHeight: 56px;
$largeHeaderHeight: 78px;

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  &.position {
    &-default {
      left: 0;
      right: 0;
    }

    &-right {
      right: 0;
    }

    &-center-horizontal {
      padding-top: $offset20;
      padding-bottom: $offset20;

      .modal-popup {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .modal-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $greyOpacity50;
  }

  .modal-wrapper {
    height: 100%;
  }

  .modal-inner {
    position: relative;
    height: 100%;
  }

  .modal-popup {
    position: absolute;
    background-color: $whiteMaster;
    border: 1px solid $blue20;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: $borderRadius6;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    &.modal-popup-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.modal-popup-with-arrow {
      margin-top: $offset2;
    }

    &:not(.no-header-desktop) {
      &.header-size-medium {
        .modal-content {
          height: calc(100% - $mediumHeaderHeight);
        }
      }

      &.header-size-large {
        .modal-content {
          height: calc(100% - $largeHeaderHeight);
        }
      }
    }

    &.header-size-medium {
      .modal-header {
        min-height: $mediumHeaderHeight;
      }

      .modal-close {
        height: $mediumHeaderHeight;
      }
    }

    &.header-size-large {
      .modal-header {
        min-height: $largeHeaderHeight;
      }

      .modal-close {
        height: $largeHeaderHeight;
      }
    }

    &.rounded-scroll {
      overflow: hidden;
    }

    &.offset-primary,
    &.offset-secondary {
      .modal-content {
        padding-left: $offset6;
        padding-right: $offset6;
      }
    }

    &.offset-none {
      .modal-content {
        padding: 0;
        height: 100%;
      }
    }
  }

  .modal-content {
    padding: 0 $offset12 $offset6;
    overflow-y: auto;
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0 $offset6;

    &.no-header-desktop {
      display: none;
    }

    &.delimiter {
      border-bottom: 1px solid $blue20;
    }
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: $offset6;
    height: 56px;
    cursor: pointer;
    padding: $offset2;
    display: flex;
    z-index: 10;
    align-items: center;

    &.no-close-desktop {
      display: none;
    }
  }

  .modal-arrow {
    position: fixed;
    width: 17px;
    height: 8px;
    z-index: 999;
  }

  .modal-popup.no-offset {
    .modal-content {
      padding: 0;
      height: 100%;
    }
  }

  .modal-back {
    transform: rotate(180deg);
    cursor: pointer;
  }

  .modal-title {
    display: block;

    &.no-title-desktop {
      display: none;
    }
  }
}
