@import 'globalStyles/variables';

.multiselect-header {
  border: 1px solid $grey200;
  display: flex;
  width: 100%;
  min-height: $largeFieldHeight;
  justify-content: space-between;
  border-radius: $borderRadius4;
  align-items: center;
  padding: 0 $offset4 0 $offset2;

  &-item-icons {
    display: flex;
  }

  &.readonly {
    background-color: $grey50;
  }

  .multiselect-header-item-block {
    padding: $offset2 0;
    display: flex;
    gap: $offset2;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90%;
    .empty {
      width: 100%;
    }
  }

  .multiselect-header-item-input-inner {
    width: 100%;
    padding-left: $offset2;
  }

  &-item-icon-close {
    background: $blue10;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: $offset2;
    cursor: pointer;
  }

  &-item-icon-arrow {
    transition: transform 300ms;
    margin-left: $offset2;
    transform: rotate(180deg);
    cursor: pointer;
  }
}

.multiselect-header-error {
  border-color: $red600Master;
}

.multiselect-header:hover:not(.readonly) {
  border-color: $grey400;
}

.multiselect-header-active {
  border-color: $blue700;

  .multiselect-header-item-icon-arrow {
    transition: transform 300ms;
    transform: rotate(0);
  }
}

.multiselect-header.multiselect-header-active:hover {
  border-color: $blue700;
}
