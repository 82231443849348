@import 'globalStyles/variables';

.wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;

  .header {
    margin: $offset10 $offset10 $offset12;

    .header-text {
      height: 115px;
    }
  }

  .carousel-item {
    width: 83.4%;
    background-color: $whiteMaster;
    border-radius: $borderRadius6;
    margin: 0 auto;

    .mock-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: $offset6;

      .slide-header {
        margin: $offset6 0 $offset4;
      }

      .benefits {
        gap: $offset2;
        display: flex;
        flex-direction: column;

        .benefit {
          display: flex;
          align-items: center;

          svg {
            margin-right: $offset3;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 40px;
    width: 235px;
    margin-left: $offset10;
  }
}
