@import 'globalStyles/variables';
@import 'globalStyles/typographyMixins';

$size: 10px;

.action-items {
  position: static;
  display: flex;
  justify-content: center;
  cursor: pointer;

  & > svg:hover {
    rect {
      fill: $blue700;
    }
    path {
      fill: $whiteMaster;
    }
  }

  .list-item {
    position: absolute;
    border: 1px solid $grey100;
    right: 100px;
    z-index: 10;
    box-shadow: $shadowSoft;
    background-color: $whiteMaster;
    border-radius: $borderRadius4;

    .item {
      padding: $offset3;
      height: 48px;
      display: flex;
      white-space: nowrap;
      align-items: center;
      border-bottom: 1px solid $grey100;
      text-decoration: none;

      &:last-child {
        border: none;
      }

      &:not(.disabled):hover {
        background-color: $blue10;
        color: $blue700;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: $blue10;
      }
    }
  }
}
