@import 'globalStyles/variables';
@import 'globalStyles/mixins';

.table {
  border: 1px solid $blue20;
  margin-top: $offset6;
  border-radius: $borderRadius6;
  position: relative;
  overflow: hidden;
}

.info-table-total {
  display: flex;
  width: 100%;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  margin-top: $offset6;
}

.info-table-header-item {
  padding: $offset2 $offset4;
  background: $blue10;
  width: 100%;
  padding: 0;
  border-right: 1px solid $blue20;
}

.info-table-footer-total,
.info-table-footer-item {
  padding: $offset2 $offset4;
  background: $blue10;
  border-top: 1px solid $blue20;
  border-right: 1px solid $blue20;
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: $borderRadius6;
}

.info-table-footer-item {
  background: $whiteMaster;
}

.info-table-footer-item:last-child {
  border-right: none;
  border-bottom-right-radius: $borderRadius6;
}
