@import 'globalStyles/variables';

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;

  &-left {
    background-color: $blue900Master;
    width: 38%;
  }

  &-right {
    padding: 0 $offset10 0;
    width: 62%;
  }
}
