@import 'globalStyles/mixins';

.list {
  @include listWrapper();
}

.pagination {
  margin-top: $offset6;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: $offset3;
  border-bottom: 1px solid $blue20;
}

.full-screen-modal {
  width: 1248px;
  padding: $offset6;
}

.extra {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
