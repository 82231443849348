@import 'globalStyles/variables';

.upload-files {
  display: flex;
  flex-direction: column;
}

.upload-files-content {
  display: flex;
  align-items: center;
}

.form-item {
  width: auto;
}

.file-format-block {
  display: flex;
  flex-direction: column;
  gap: $offset2;
  margin-left: auto;
}

.supplier-info {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $offset4;
  border: 1px solid $blue20;
  border-radius: $borderRadius6;
  box-shadow: $shadowSoft;
  background-color: $whiteMaster;
  margin-bottom: $offset6;
}

.right-block {
  display: flex;
  align-items: center;
}

.review-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rating {
  display: flex;
  align-items: center;

  &-icon {
    margin-right: $offset1;
  }
}

.form {
  width: 100%;
  margin-bottom: $offset6;
}

.form-items-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal {
  width: 576px;
}

.description {
  margin: $offset6 0;
  text-align: center;
}

.buttons {
  display: flex;
  gap: $offset6;
}
