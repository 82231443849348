@import 'globalStyles/variables';

.password-must-have {
  ul {
    padding-top: $offset1;
    padding-left: 0;
    margin-top: 0;

    li {
      display: flex;
      align-items: center;
      padding-bottom: $offset1;

      .password-must-have-title {
        margin-left: $offset2;
      }
    }

    li::before {
      content: '•';
      color: $grey500;
      margin: 0 $offset1;
    }

    li.password-must-have-check::before {
      content: '';
      margin: 0;
    }
  }
}
