@import 'globalStyles/mixins';

.list {
  @include listWrapper();

  .header {
    display: flex;
    justify-content: flex-end;
    padding: $offset3 $offset4;
    border-bottom: 1px solid $blue20;
    gap: $offset4;

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue10;
      border-radius: $borderRadius4;
      width: 132px;
      height: 32px;
    }
  }

  .table {
    width: 100%;

    .vehicle {
      display: flex;
      align-items: center;

      .image {
        margin-right: $offset2;
        width: 52px;
        height: 34px;
      }
    }
  }
}

.pagination {
  margin-top: $offset6;
  padding-bottom: $offset15;
}
