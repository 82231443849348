@import 'globalStyles/variables';

.button {
  margin-right: $offset4;
  margin-left: $offset4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue10;
  border-radius: $borderRadius4;
  height: 32px;
  width: 108px;

  &.disabled {
    background-color: $grey200;
  }
}
