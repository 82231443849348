@import 'globalStyles/variables';

.simple-pagination {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue10;
  border-radius: 0 0 $borderRadius6 $borderRadius6;

  &-arrow-left {
    cursor: pointer;
    margin-right: $offset2;
  }

  &-arrow-right {
    cursor: pointer;
    margin-left: $offset2;
    transform: rotate(180deg);
  }
}
