@import 'globalStyles/variables';

.form-alert-label {
  display: flex;
  align-items: self-start;
  margin-top: $offset2;

  &.bottom-offset {
    margin-bottom: $offset2;
  }

  &-error {
    color: $red600Master;
  }

  &-warning {
    color: $yellow700;
  }

  &-success {
    color: $green700Master;
  }

  &-hint {
    color: $grey500;
  }

  .form-alert-label-svg-alert {
    position: unset;
    margin-right: $offset2;
  }
}
