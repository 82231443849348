@import 'globalStyles/variables';

.signup-form {
  max-width: 720px;
  margin: $offset26 auto 0;

  .header {
    margin-bottom: $offset2;
  }

  .form-fields {
    width: 100%;
    margin-top: $offset8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .badge {
      margin-bottom: $offset4;
    }

    .button-wrapper {
      margin-top: $offset4;
      width: 288px;
    }
  }

  .link-wrapper {
    position: absolute;
    top: 42px;
  }
}
