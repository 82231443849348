@import 'globalStyles/variables';

.table-container {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.table {
  border-collapse: collapse;
}

.no-data {
  padding: $offset20 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .no-data-image {
    width: 200px;
    height: 127px;
    margin-bottom: $offset4;
  }
}

.table-container-sticky {
  max-height: 504px;
  overflow-y: hidden;
  overflow-x: hidden;

  .table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-foot {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}

.table-container-sticky::-webkit-scrollbar {
  width: 0;
}

.table-container-sticky:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.table-container-sticky:hover {
  overflow-y: auto;
  overflow-x: auto;
}

.table-container-sticky::-webkit-scrollbar-thumb {
  background-color: $blue20;
  border-radius: 4px;
}
