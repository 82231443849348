@import 'globalStyles/variables';

.row-with-actions {
  display: flex;
  gap: $offset6;
}

.price {
  width: 100%;
}

.action {
  min-width: 48px;
  margin-top: 25px; // * label height
}

.row {
  & + .row {
    border-top: 1px solid $blue20;
    padding-top: $offset6;
  }
}
