@import 'globalStyles/variables';

.item-wrapper {
  border-bottom: 1px solid $grey200;

  .item {
    display: flex;
    align-items: center;
    height: 48px;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}
