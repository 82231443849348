@import 'globalStyles/variables';
@import 'globalStyles/typographyStyles';

.button {
  padding: 0 8px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  &-border-radius-large {
    border-radius: $borderRadius6;
  }

  &-border-radius-medium {
    border-radius: $borderRadius4;
  }

  &-border-radius-small {
    border-radius: $borderRadius2;
  }

  &-noborders {
    &-left {
      border-radius: 0 $borderRadius4 $borderRadius4 0;
    }

    &-right {
      border-radius: $borderRadius4 0 0 $borderRadius4;
    }

    &-all {
      border-radius: 0;
    }
  }

  &.button-size {
    &-small {
      height: $smallFieldHeight;
    }

    &-medium {
      height: $mediumFieldHeight;
    }

    &-large {
      height: $largeFieldHeight;
    }

    &-auto {
      height: auto;
    }
  }

  &-variant {
    &-primary {
      background: $blue700;
      color: $whiteMaster;
      transition: background 0.3s;

      &:not(:disabled):hover {
        background: $blue500;
      }

      &.button-disabled {
        background: $grey100;
        cursor: not-allowed;
        color: $grey500;
      }
    }

    &-secondary {
      background: $blue10;
      color: $blue700;
      border: 1px solid transparent;
      letter-spacing: -0.005em;
      transition: border-color 0.3s;

      &:not(:disabled):hover {
        background: $blue50;
      }

      &:not(:disabled):active {
        background: $blue20;
      }

      &.button-disabled {
        background: $grey100;
        cursor: not-allowed;
        color: $grey500;
      }
    }

    &-link {
      padding: 0;
      color: $blue700;
      background: transparent;
      width: 100%;
      transition: text-decoration 0.3s;

      &:hover {
        text-decoration: underline;
      }

      &.button-size-auto {
        width: auto;
      }

      &.button-disabled {
        cursor: not-allowed;
        color: $blueSecondary;
        text-decoration: none;
      }
    }

    &-tertiary {
      background: $green700Master;
      color: $whiteMaster;
      transition: background 0.3s;

      &:not(:disabled):hover {
        background: $green500;
      }

      &:not(:disabled):active {
        background: $green400;
      }

      &.button-disabled {
        background: $grey100;
        cursor: not-allowed;
        color: $grey500;
      }
    }

    &-outline {
      background: $blue10;
      color: $blue700;
      transition: background 0.3s;
      border: 1px solid $blue700;

      &:not(:disabled):hover {
        background: $blue50;
      }

      &.button-disabled {
        background: $grey100;
        cursor: not-allowed;
        color: $grey500;
      }
    }
  }

  &-align {
    &-center {
      justify-content: center;
    }

    &-left {
      justify-content: flex-start;
    }
  }

  &-loading {
    cursor: default;
  }

  .button-icon-left,
  .button-icon-right {
    margin-right: $offset1;
    display: inline-flex;
    align-items: center;
  }

  .button-icon-right {
    margin-left: $offset2;
  }

  .button-label {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .button-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, 0.46);
    border-radius: $borderRadius2;
    overflow: hidden;
    display: flex;

    &::after {
      content: ' ';
      display: block;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 50% 50%;
      justify-content: center;
      align-items: center;
      background-image: url(../../../assets/img/spinner.gif);
      width: 15%;
      min-width: 50px;
      height: 100%;
      margin: auto;
      transform: scale(1.4);
    }
  }
}
